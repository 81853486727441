export const FirebaseConfig = {
	"projectId": "ridpo-the-road-jet-service",
	"appId": "1:880262920907:web:c7e954fc4848153fedf94e",
	"databaseURL": "https://ridpo-the-road-jet-service-default-rtdb.firebaseio.com",
	"storageBucket": "ridpo-the-road-jet-service.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyC2Lvl88-RqWmESXopnjEwOszInADYm_-g",
	"authDomain": "ridpo-the-road-jet-service.firebaseapp.com",
	"messagingSenderId": "880262920907",
	"measurementId": "G-1ZMQS3KNM4"
};